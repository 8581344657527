import React, { useState, useEffect } from "react";
import Compressor from "compressorjs";
import UploadImageIcon from "../../images/uploadImg.png";
import { useTranslation } from "react-i18next";

const ALLOWED_EXTENSIONS = [
  "jpg",
  "jpeg",
  "png",
  "gif",
  "bmp",
  "tiff",
  "webp",
  "svg",
  "pdf",
  "doc",
  "docx",
  "xls",
  "xlsx",
  "ppt",
  "pptx",
  "csv",
  "txt",
  "rtf",
  "odt",
  "dwg",
  "dxf",
  "zip",
  "rar",
  "7z",
  "tar",
  "gz",
  "mp3",
  "wav",
  "aac",
  "flac",
  "mp4",
  "mov",
  "avi",
  "mkv",
  "wmv",
];

// Build a string for the accept attribute
const ACCEPT_STRING = ALLOWED_EXTENSIONS.map((ext) => `.${ext}`).join(",");

// Example limit: 10MB
const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB in bytes

const ImageUpload = ({
  imgSrc,
  setImgSrc,
  onBlurCapture,
  id,
  removePrevAssetsData,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    const activeId = localStorage.getItem("activeAssetsId");
    const saved = localStorage.getItem("images");

    if (saved && id === activeId) {
      try {
        const parsed = JSON.parse(saved); // array of base64 strings
        setImgSrc(parsed);
      } catch (err) {
        console.error("Error parsing localStorage images:", err);
      }
    }
  }, [id, setImgSrc]);

  // Helper: is it an image?
  const isImage = (fileType) => fileType.includes("image");

  // Helper: is it a PDF?
  const isPdfBase64 = (base64String) =>
    base64String.startsWith("data:application/pdf");

  // Helper: is it an audio file?
  const isAudioBase64 = (base64String) =>
    base64String.startsWith("data:audio");

  // Handler for file input
  const imageCaptureHandler = (e) => {
    let preImg = [...imgSrc];
    removePrevAssetsData();

    const fileArray = Array.from(e.target.files);
    localStorage.setItem("activeAssetsId", id);

    fileArray.forEach((fileData) => {
      // Check file size before anything else
      if (fileData.size > MAX_FILE_SIZE) {
        alert("File size is more than 10 MB. Please select a smaller file.");
        // Skip processing this file
        return;
      }

      // If it's an image, compress it
      if (isImage(fileData.type)) {
        new Compressor(fileData, {
          quality: 0.4,
          success(result) {
            const reader = new FileReader();
            reader.readAsDataURL(result);
            reader.onload = (ev) => {
              const base64Str = ev.target.result;
              preImg.push(base64Str);
              setImgSrc([...preImg]);
              localStorage.setItem("images", JSON.stringify(preImg));
            };
          },
          error(err) {
            console.error(err.message);
          },
        });
      }
      // If it's a PDF
      else if (fileData.type === "application/pdf") {
        const reader = new FileReader();
        reader.readAsDataURL(fileData);
        reader.onload = (ev) => {
          const base64Str = ev.target.result;
          preImg.push(base64Str);
          setImgSrc([...preImg]);
          localStorage.setItem("images", JSON.stringify(preImg));
        };
      }
      // Otherwise (doc, xls, video, audio, etc.)
      else {
        const reader = new FileReader();
        reader.readAsDataURL(fileData);
        reader.onload = (ev) => {
          const base64Str = ev.target.result;
          preImg.push(base64Str);
          setImgSrc([...preImg]);
          localStorage.setItem("images", JSON.stringify(preImg));
        };
      }
    });
  };

  // Remove an item by index
  const onRemoveHandler = (indexToRemove) => {
    const updated = imgSrc.filter((_, i) => i !== indexToRemove);
    setImgSrc(updated);
    localStorage.setItem("images", JSON.stringify(updated));
  };

  return (
    <>
      {/* Render existing base64 strings */}
      {imgSrc.length > 0 && (
        <div className="container">
          <div style={{ maxWidth: "390px", margin: "auto" }}>
            <div className="d-flex flex-wrap">
              {imgSrc.map((base64Str, i) => (
                <div key={i} className="px-1 mx-auto">
                  <div className="upload-image mb-3 position-relative">
                    <span
                      onClick={() => onRemoveHandler(i)}
                      className="remove-image"
                    >
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15 0C6.72923 0 0 6.72923 0 15C0 23.2708 6.72923 30 15 30C23.2708 30 30 23.2708 30 15C30 6.72923 23.2708 0 15 0ZM15 2.30769C22.0235 2.30769 27.6923 7.97654 27.6923 15C27.6923 22.0235 22.0235 27.6923 15 27.6923C7.97654 27.6923 2.30769 22.0235 2.30769 15C2.30769 7.97654 7.97654 2.30769 15 2.30769ZM10.6385 8.97692L8.97692 10.6385L13.3431 15L8.97923 19.3615L10.6408 21.0231L15 16.6581L19.3615 21.0196L21.0231 19.3615L16.6581 15L21.0196 10.6385L19.3615 8.97692L15 13.3431L10.6385 8.97923V8.97692Z"
                          fill="black"
                        />
                      </svg>
                    </span>

                    {/* Conditionally render based on Base64 prefix */}
                    {base64Str.startsWith("data:image") ? (
                      // It's an image
                      <img src={base64Str} alt="" />
                    ) : isPdfBase64(base64Str) ? (
                      // It's a PDF
                      <div style={{ width: "150px", height: "200px" }}>
                        <embed
                          src={base64Str}
                          type="application/pdf"
                          width="100%"
                          height="100%"
                        />
                      </div>
                    ) : isAudioBase64(base64Str) ? (
                      // It's an audio file (e.g., mp3)
                      <div>
                        <audio controls>
                          <source src={base64Str} type="audio/mpeg" />
                          Your browser does not support the audio element.
                        </audio>
                      </div>
                    ) : (
                      // Otherwise, treat as unknown/other
                      <div>
                        <p>Other File</p>
                        <a
                          href={base64Str}
                          download={`file-${i}`}
                          style={{ textDecoration: "underline", color: "blue" }}
                        >
                          Download
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* File input for images + all doc/video/audio extensions */}
      <div className="text-center d-flex px-2 justify-content-center align-items-center">
        <button className="default-btn d-flex flex-column align-items-center w-100 position-relative text-black py-4 my-3">
          <input
            id="icon-button-file"
            type="file"
            className="input-file"
            accept={ACCEPT_STRING}
            multiple
            name="imgSrc"
            onBlurCapture={onBlurCapture}
            onChange={imageCaptureHandler}
          />
          <img
            style={{ width: "35px", height: "35px" }}
            src={UploadImageIcon}
            alt=""
          />
          <span className="_text-dark-graish">{t("Add More")}</span>
        </button>
      </div>
    </>
  );
};

export default ImageUpload;
