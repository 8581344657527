import React, { useState } from "react";
import Transitions from "../../hoc/Transitions";
import { useDispatch, useSelector } from "react-redux";
import {
  inspactAuthorizationAction,
  submitCausesAction,
  userDataAction,
} from "../../redux/action/ProductAction";
import OtpInput from "react-otp-input";
import { withRouter, Redirect } from "react-router-dom";
import Loader from "../common/Loader";
import { useTranslation } from "react-i18next";
const Verifiacation = ({ history }) => {
  const submitLoading = useSelector(
    (state) => state.Product.causesSubmit?.loading
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.Product.isLoading);
  const isAuth = useSelector((state) => state.Product.isAuth);
  const isInspactRoute = useSelector((state) => state.Product.isInspactRoute);
  const assetsDetails = useSelector((state) => state.Product.assetsDetails);
  const [otp, setOtp] = useState();
  const [isWrongOtp, setIsWrongOtp] = useState(false);

  const submitOtp = (e) => {
    setIsWrongOtp(false);
    setOtp(e);
    if (e.length === 6) {
      let confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(e)
        .then((result) => {
          const user = result;
          const id = localStorage.getItem("activeAssetsId");
          localStorage.setItem("token", user._tokenResponse.idToken);
          localStorage.setItem(
            "phone",
            user._tokenResponse.phoneNumber.replace(/\D/g, "").slice(-10)
          );
          const data = {
            sysma_token: user._tokenResponse.idToken,
            phone: user._tokenResponse.phoneNumber
              .replace(/\D/g, "")
              .slice(-10),
          };
          localStorage.setItem("Authentication",true);
          dispatch(userDataAction(data));
          // REMOVE COUNTRY CODE FROM THE PHONE NUMBER
          const phone = result._tokenResponse.phoneNumber
            .replace(/\D/g, "")
            .slice(-10);
          if (isInspactRoute) {
            const showAlert = true;
            dispatch(
              inspactAuthorizationAction(phone, history, id, showAlert, t, assetsDetails.company_id)
            );
          } else {
            // submitCauseHandler(e);
            // history.push(url(`/report/${id}`);
            submitCauses();
          }
          setOtp();
        })
        .catch((error) => {
          setIsWrongOtp(true);
        });
    }
  };
  const removeAssetsDataFromLocal = () => {
    localStorage.removeItem("activeAssetsId");
    localStorage.removeItem("title");
    localStorage.removeItem("comment");
    localStorage.removeItem("images");
    localStorage.removeItem("status");
  };
  const submitCauses = () => {
    const ques = JSON.parse(localStorage.getItem("selectedQue"));
    const assetsValue = ques.filter((obj) => obj.isChecked === true);
    let causes = [];
    assetsValue.map((obj) => {
      if (obj.isChecked === true) {
        causes.push(obj.id);
      }
    });
    const id = localStorage.getItem("activeAssetsId");
    const title = localStorage.getItem("title");
    const comment = localStorage.getItem("comment");
    const imgSrc = JSON.parse(localStorage.getItem("images"));
    const status = localStorage.getItem("status");
    const phone = localStorage.getItem("phone");
    const data = {
      asset_id: id,
      title: title,
      causes: causes,
      images: imgSrc,
      description: comment,
      asset_working_status: status,
      phone: phone,
    };
    dispatch(submitCausesAction(data, removeAssetsDataFromLocal, history));
  };
  const isView = window.confirmationResult;
  if (submitLoading) {
    return (
      <>
        <Transitions>
          <div className="h-100 d-flex flex-grow-1 justify-content-center align-items-center">
            <Loader />
          </div>
        </Transitions>
      </>
    );
  }
  if (!isAuth && isView) {
    return (
      <Transitions>
        <div className="d-flex mt-2 h-100 flex-column">
          <h3 className="fs-16 text-center _bg-primary text-white py-2 fw-700 text-black">
            {t("Enter Your OTP")}
          </h3>
          <div className=" d-flex flex-column  align-items-center  pt-5 ">
            <h6 className="fs-16 mb-3 _text-dark-graish">
              {t("Verification OTP")}
            </h6>
            <OtpInput
              value={otp}
              onChange={(e) => submitOtp(e)}
              numInputs={6}
              isInputNum={true}
              hasErrored={isWrongOtp}
              shouldAutoFocus={true}
              errorStyle={{ border: `1px solid red` }}
              inputStyle={{
                border: "1px solid transparent",
                background: "#e8e7f6",
                borderRadius: "8px",
                width: "44px",
                height: "44px",
                fontSize: "12px",
                color: "#000",
                fontWeight: "400",
                caretColor: "blue",
              }}
              focusStyle={{
                border: `1px solid blue`,
                outline: "none",
                background: "#f6f6f6",
              }}
              separator={<span style={{ width: "8px" }}></span>}
            />
          </div>
          {isWrongOtp && (
            <>
              <div className="d-flex justify-content-center">
                <span className="error">
                  {t("You have entered the wrong otp")}
                </span>
              </div>
            </>
          )}
        </div>
      </Transitions>
    );
  } else {
    return (
      <>
        {loading ? (
          <div className="h-100 d-flex flex-grow-1 justify-content-center align-items-center">
            <Loader />
          </div>
        ) : (
          <></>
        )}
      </>
    );
  }
};

export default withRouter(Verifiacation);
